$primary: #0f99a3;
$secondary: #f152ff;
$primary-dark: #11fdbe;

$white: #fff;
$black: #000;
$darkgray: #999;
$lightgray: #ccc;

$txt: #000;
$txtRevert: #000;
$altTxt: #f8f6f0;
